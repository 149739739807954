/* eslint-disable unicorn/no-nested-ternary */
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router';
import styled from 'styled-components';
import Icon from '../../components/icon';

import MSWordIcon from '../../components/msword-icon';
import Spinner from '../../components/spinner';
import { useDocument } from '../../context/document-context';
import { track, TrackingType } from '../../tracking/ga';
import EditorWorkspace from './editor-workspace';

import { UploadError } from '../../components/upload-error';

type Props = RouteChildrenProps<{ sessionId?: string }>;

const MainPage: React.FC<Props> = () => {
  useEffect(() => {
    track('pageView');
  }, []);

  const {
    dropZone: { isDragActive, isDragReject, getRootProps, getInputProps, open },
    state,
    isUploading,
  } = useDocument({
    noKeyboard: true,
    noClick: true,
  });

  if (state.matches('uninitialized')) {
    return null;
  }
  if (isUploading) {
    /**
     * shows when only when we are uploading this is a return to stop dragging
     * and uploading new file while the first one is stilling uploading
     */

    return (
      <DropZone>
        <Spinner size={400} speed={4} />
        <InnerContent>
          <MSWordIcon height={90} look={'default'} />
          <HeroText>{state.context.document?.name}</HeroText>
          <HelperText>Uploading your document...</HelperText>
        </InnerContent>
      </DropZone>
    );
  }

  /**
   * shows only when we can't handle the document being uploaded
   * stops the drag and drop
   */
  if (state.hasTag('uploadFailed')) {
    return <UploadError data={state.context} />;
  }

  /**
   * default to showing upload circle
   */
  let content = (
    <StyledDropZone
      onClick={() => {
        track(
          'event',
          TrackingType.Category.uploadPage,
          TrackingType.Actions.uploadIntent,
          TrackingType.Labels.uploadCentre,
        );
        open();
      }}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <input {...getInputProps({})} />
      <Content>
        {isDragReject ? (
          <>
            <Icon icon={faTimes} color="#CC0044" size="7x" />
            <HeroText>Unsupported file format</HeroText>
            <UploadButton>Please upload a Microsoft Word file</UploadButton>
          </>
        ) : (
          <>
            <MSWordIcon height={90} look={'default'} />
            <HeroText>Upload your Word file here</HeroText>
            <UploadButton>Click here to upload</UploadButton>
          </>
        )}
      </Content>
    </StyledDropZone>
  );

  /**
   * show editor when preview text is available, and the user is not trying to upload again
   */
  if (state.context.preview && !isDragActive) {
    content = <EditorWorkspace />;
  }
  return (
    <DropZone
      {...getRootProps({
        onDrop: () =>
          track(
            'event',
            TrackingType.Category.uploadPage,
            TrackingType.Actions.uploadIntent,
            TrackingType.Labels.uploadDrag,
          ),
      })}
    >
      {content}
    </DropZone>
  );
};

export default MainPage;

const DropZone = styled.div`
  all: inherit;
  position: relative;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

const StyledDropZone = styled.div<{ isDragReject?: boolean; isDragActive?: boolean }>`
  margin: 0px 40px;
  display: grid;
  place-items: center;
  height: 40vw;
  width: 40vw;
  max-height: 60rem;
  max-width: 60rem;
  line-height: 2.5rem;
  text-align: center;
  border-width: 0.3rem;
  border-style: dashed;
  border-color: ${({ isDragReject, isDragActive }) => {
    return isDragReject ? '#CC0044' : isDragActive ? '#0060FF' : '#AFB7CA';
  }};
  background-color: ${({ isDragActive }) => (isDragActive ? '#E6F0FF' : '#ffffff')};
  border-radius: 50%;
  outline: none;
  transition: 0.1s ease-in-out;
  transition-property: background-color, border-color;
  &:hover {
    border-color: #0060ff;
    background-color: #e6f0ff;
    cursor: pointer;
  }
`;

const Content = styled.div`
  gap: 1.6rem;
  display: grid;
  place-items: center;
`;

const InnerContent = styled(Content)`
  position: absolute;
`;

const HeroText = styled.h1`
  color: ${({ theme }) => theme.palette.text.main};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: 400;
`;

const UploadButton = styled.button`
  border: none;
  background: none;
  color: blue;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSize.regular};
  cursor: pointer;
`;

const HelperText = styled.p`
  color: ${({ theme }) => theme.palette.text.light};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;
